import { useEffect } from 'react';

const UPDATE_PAGE_TITLE_EVENT = 'updatePageTitle';

/**
* Used to trigger event to update html title
*/
export function triggerUsePageTitleUpdate(newTitle?: string) {
  const event = new CustomEvent(UPDATE_PAGE_TITLE_EVENT, {
    detail: { title: newTitle },
  });
  window.dispatchEvent(event);
}

/**
* Hook that adds event listener to update html title
* and updates the title whenever UPDATE_PAGE_TITLE_EVENT is sent
*/
export function usePageTitle(initialTitle?: string) {
  useEffect(() => {
    const updateTitle = (event: CustomEvent) => {
      const newTitle = event.detail.title || initialTitle
      document.title = newTitle ? `${newTitle} | User Interviews` : 'User Interviews';
    };

    window.addEventListener(UPDATE_PAGE_TITLE_EVENT, updateTitle as EventListener);
    // Dispatch event on mount to set the initial title
    triggerUsePageTitleUpdate()

    return () => {
      window.removeEventListener(UPDATE_PAGE_TITLE_EVENT, updateTitle as EventListener);
    };
  }, [initialTitle]);
}
