import { useMemo } from 'react';
import { Ownership } from 'lib/generated_constants/ownership';
import { useRailsContext } from 'hooks/use_rails_context';
// eslint-disable-next-line no-restricted-imports
import { DEFAULT_NAMESPACE, initializeAppsignal } from 'lib/telemetry/appsignal';
import { ENV } from 'lib/react_on_rails/env';

export function useAppsignal({ namespace }: {namespace: Ownership | typeof DEFAULT_NAMESPACE}) {
  const railsContext = useRailsContext();
  const appsignal = useMemo(() => initializeAppsignal({
    namespace,
    // Not all component trees are wrapped by rails context providers yet.
    // Particularly staff & participant pages.
    env: railsContext ? railsContext.env : ENV,
  }), [namespace, railsContext]);

  if (appsignal) {
    appsignal.addBreadcrumb = appsignal.addBreadcrumb.bind(appsignal)
    appsignal.addDecorator = appsignal.addDecorator.bind(appsignal)
    appsignal.addOverride = appsignal.addOverride.bind(appsignal)
    appsignal.createSpan = appsignal.createSpan.bind(appsignal)
    appsignal.demo = appsignal.demo.bind(appsignal)
    appsignal.send = appsignal.send.bind(appsignal)
    appsignal.sendError = appsignal.sendError.bind(appsignal)
    appsignal.use = appsignal.use.bind(appsignal)
    appsignal.wrap = appsignal.wrap.bind(appsignal)
  }

  return appsignal;
}
